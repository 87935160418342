body {
	color: #666666;
	font-size: 18px;
	font-family: 'Verdana';	
}

.postTitle {
  font-family: 'Rancho';
  font-weight: 700;
  font-size: 28px;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}


.header_image {
  height: 300px;
  background-size: cover;
  background-position: center;
}

.header_image {
  background-image: url(https://mathcurious.com/wp-content/uploads/2019/12/header3-1.gif);
}

.home-nav {
  background: #82aa49;
  width: 100%;
  z-index: 999;
}

.top-menu {
  padding-right: 0;
}

.header-logo {
  float: left;
  z-index: 111;
}

#cssmenu {
  line-height: 1;
  background: none;
  float: right;
}

#cssmenu, #cssmenu ul, #cssmenu ul li, #cssmenu ul li a, #cssmenu #menu-button {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#cssmenu #menu-button {
  display: none;
}

#cssmenu > ul > li {
  float: left;
}

#cssmenu > ul > li > a {
    padding: 25px 15px;
    font-size: 14px;
    letter-spacing: 1px;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    -webkit-transition: color .2s ease;
    -moz-transition: color .2s ease;
    -ms-transition: color .2s ease;
    -o-transition: color .2s ease;
    transition: color .2s ease;
}





@media (max-width: 576px) { 
  .puzzleImage {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 576px) { 
  .puzzleImage {
    max-width: 100%;
    height: auto;
  }
}


@media (min-width: 768px) { 
 
}


@media (min-width: 992px) { 
  
}


@media (min-width: 1200px) { 
 
}

